import { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { SketchRef } from '@/components/Sketch/sketch';
import { processImage, convertImageDataToBase64 } from './utils';
import {
    convertCityLayout,
    // createFluxTask,
    generateRoadMap,
    generateUSDZ,
    // getClipsegResult,
    // getFluxResult,
    uploadMask
} from "@/service/taskService";

import backIcon from "@/assets/back.png";
import StepOne from './StepOne';
import StepTwo from './StepTwo';
// import StepThree from './StepThree';
import StepFour from './StepFour';


function Mask() {
    const sketchRef = useRef<null | SketchRef>(null);
    const [_, setInputPaths] = useState<{ boundaryPath: string, densityPath: string }>({ boundaryPath: "", densityPath: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [roadMap, setRoadMap] = useState<{ image: string, original_json: any }>({ image: "", original_json: "" });
    // const [threeDMap, setThreeDMap] = useState<string>("");
    const [step, setStep] = useState(1);
    const isRestart = useRef(false);
    const [saveImageData, setSaveImageData] = useState<ImageData | null>(null);
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
    const [usdzResult, setUsdzResult] = useState<any>(null);

    const uploadMasks = async (mask1Base64: string, mask2Base64: string) => {
        const [res1, res2] = await Promise.all([
            uploadMask({ "imgcontent": mask1Base64 }),
            uploadMask({ "imgcontent": mask2Base64 })
        ]);
        return {
            boundary_input: res1.data.image_path,
            density_input: res2.data.image_path
        };
    };

    const generateRoadMapStep = async (boundary_input: string, density_input: string) => {
        const roadMapData = {
            boundary_input,
            density_input,
            style_input: "https://webagent-screenshot.s3.amazonaws.com/color_input.png",
            max_attempts: 500,
            density_percent: 0.3,
            is_square: false,
            inmem: true,
            only_json: false,
            show_axes: false
        }

        const roadMapResult = await generateRoadMap(roadMapData);
        return roadMapResult.data;
    };

    // const createFluxTaskStep = async (roadmap_url: string) => {
    //     const fluxData = {
    //         prompt: "a small village with house & few trees from absolute top-down view",
    //         roadmap_input: roadmap_url,
    //         flux_config: {
    //             gen_img_width: 512,
    //             gen_img_height: 512,
    //             controlnet_weight: 0.9,
    //             controlnet_start: 0,
    //             controlnet_stop: 0.6,
    //             batch_size: 1,
    //             learn_step: 25
    //         }
    //     };

    //     const fluxResult = await createFluxTask(fluxData);
    //     return fluxResult.data.prompt_id;
    // };

    useEffect(() => {
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        }
    }, []);

    // const pollFluxResult = async (promptId: string, onSuccess: (image: string) => void) => {
    //     return new Promise((resolve, reject) => {
    //         intervalIdRef.current = setInterval(async () => {
    //             try {
    //                 const flux = await getFluxResult(promptId);
    //                 console.log({ flux: flux.data });
    //                 if (flux.data.code === 200) {
    //                     if (intervalIdRef.current) {
    //                         clearInterval(intervalIdRef.current);
    //                     }
    //                     onSuccess(flux.data.image);
    //                     resolve(flux.data.image);
    //                 }
    //             } catch (err) {
    //                 if (intervalIdRef.current) {
    //                     clearInterval(intervalIdRef.current);
    //                 }
    //                 reject(err);
    //             }
    //         }, 2000);
    //     });
    // };

    // const processClipseg = async (threeDMap: string) => {
    //     const clipsegData = {
    //         imgcontent: threeDMap,
    //         prompts: ["house", "tree", "road", "grass"], // 添加需要识别的对象
    //         threshold: 0.5 // 添加识别阈值
    //     };
    //     const result = await getClipsegResult(clipsegData);
    //     return result.data;
    // };

    const convertCityLayoutStep = async (original_json: any, clipseg_json: any = []) => {
        const convertData = {
            "geojson_content": JSON.stringify(original_json),
            "clipseg_content": JSON.stringify(clipseg_json)
        }
        const result = await convertCityLayout(convertData);
        return result.data;
    };

    const generateUSDZStep = async (geojson: any) => {
        const generateData = {
            "map_size": 40,
            "upload_usdz": true,
            "upload_config": {
                "name_space": "dev",
                "project_name": "demo",
                "target_path": `test13/demo${new Date().getTime()}.usdz`
            },
            "geojson_content": JSON.stringify(geojson)
        }
        const result = await generateUSDZ(generateData);
        return result.data;
    };

    const handleStepOne = async () => {
        setIsLoading(true);
        setError(null);
        setStep(1);
        // 第一阶段：获取和处理图像数据
        var imageData;
        if (isRestart.current) {
            imageData = saveImageData;
        } else {
            imageData = sketchRef.current?.getCurrentImageData();
        }
        if (!imageData) {
            setError("Failed to get image data");
            return;
        }

        const { mask1, mask2 } = processImage(imageData);
        const mask1Base64 = convertImageDataToBase64(mask1);
        const mask2Base64 = convertImageDataToBase64(mask2);

        try {
            // 第二阶段：上传遮罩并生成道路地图
            const { boundary_input, density_input } = await uploadMasks(mask1Base64, mask2Base64);
            setInputPaths({ boundaryPath: boundary_input, densityPath: density_input });
            const roadMap = await generateRoadMapStep(boundary_input, density_input);
            setSaveImageData(imageData);
            setRoadMap({ image: roadMap.image, original_json: roadMap.geojson });
            setStep(2);
        } catch (err) {
            setError("Failed to generate road map");
        } finally {
            setIsLoading(false);
        }
    };

    // const handleStepTwo = async () => {
    //     isRestart.current = false;
    //     setIsLoading(true);
    //     console.log({ roadMap: roadMap.image });

    //     try {
    //         console.log(22);
    //         // 第三阶段：上传道路地图并创建Flux任务
    //         const res = await uploadMask({ imgcontent: roadMap.image });
    //         console.log({ res });
    //         if (!res) return
    //         const roadmap_url = res.data.image_path;
    //         const promptId = await createFluxTaskStep(roadmap_url);

    //         // 第四阶段：获取Flux结果
    //         await pollFluxResult(promptId, (image) => {
    //             setThreeDMap(image);
    //             setIsLoading(false);
    //             setStep(3);
    //         });
    //     } catch (err) {
    //         // @ts-ignore
    //         setError(err.message.toString());
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleStepThree = async () => {
        setIsLoading(true);
        isRestart.current = false;
        try {
            // const clipsegResult = await processClipseg(threeDMap);
            const convertResult = await convertCityLayoutStep(roadMap.original_json);
            console.log("original_json:", roadMap.original_json);

            const result = await generateUSDZStep(convertResult);
            setUsdzResult(result);
            setStep(4);
        } catch (err) {
            setError("Failed to process Clipseg or generate USDZ");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRepaint = () => {
        // 清除轮询定时器
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }

        // 重置所有状态到初始值
        setInputPaths({ boundaryPath: "", densityPath: "" });
        setIsLoading(false);
        setError(null);
        setRoadMap({ image: "", original_json: "" });
        // setThreeDMap("");
        setStep(1);
        isRestart.current = false;
        setSaveImageData(null);
        setUsdzResult(null);
    }

    // 在 className 中添加 disabled 状态的样式
    const buttonClassName = "border border-[#323232] rounded-md px-4 py-2 " +
        (isLoading ? "opacity-50 cursor-not-allowed" : "cursor-pointer");

    return (
        <div className="min-h-[calc(100vh-24px)] flex flex-col">
            <div className="flex items-center justify-between">
                <div className='flex'>
                    <Link to="/labs" className="flex items-center cursor-pointer">
                        <img src={backIcon} alt="back" width={14} height={14} />
                        <span className="ml-2 text-link">Back</span>
                    </Link>
                    <h2 className="ml-10">Mask to layout</h2>
                </div>
                <div>
                    <button
                        className="w-[100px] py-2 border border-[#323232] rounded-md px-4 text-sm"
                        onClick={handleRepaint}
                    >Redraw</button>
                </div>
            </div>
            <div className="p-4">
                <div className='flex flex-col xl:flex-row w-full'>
                    <div className="w-full xl:w-auto">
                        <StepOne isLoading={isLoading} handleStepOne={handleStepOne} sketchRef={sketchRef} />
                    </div>
                    <div className='mt-4 xl:mt-[56px] xl:ml-2 w-full xl:w-auto'>
                        <StepTwo {...{ isLoading, roadMap, handleStepOne, handleStepTwo: handleStepThree, buttonClassName, isRestart }} />
                    </div>
                </div>
                {step === 4 && (
                    <div className="flex mt-4 rounded-md h-[200px] w-[1208px] border border-white/10 items-center justify-center">
                        {/* {step === 2 && roadMap.image && <StepTwo {...{ isLoading, roadMap, handleStepOne, handleStepTwo:handleStepThree, buttonClassName, isRestart }} />} */}
                        {/* {step === 3 && threeDMap && <StepThree {...{ isLoading, threeDMap, handleStepTwo, handleStepThree, buttonClassName, isRestart }} />} */}
                        {step === 4 && <StepFour usdzResult={usdzResult} />}
                        {/*step === 4 && <iframe src="https://cloudzeta.com/player/m4wi7tbjojp6ybiz" />*/}
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Mask;