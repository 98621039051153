import { useCallback, useEffect, useState } from "react";
import { Tooltip } from "antd";

import {
    DEFAULT_MAPGEN_SELECT,
    MAPGEN_SIZE_KEYWORDS,
    MAPGEN_TYPE_KEYWORDS,
    MAPGEN_VAILLAGE_KEYWORDS,
} from "./constants";
import deleteVaillageIcon from "@/assets/mapgen/deleteVaillage.svg";
import largeIcon from "@/assets/mapgen/large.svg";
import mediumIcon from "@/assets/mapgen/medium.svg";
import mountainIcon from "@/assets/mapgen/mountain.svg";
import panIcon from "@/assets/mapgen/move.svg";
import oceanIcon from "@/assets/mapgen/ocean.svg";
import redoIcon from "@/assets/mapgen/redo.svg";
import resetIcon from "@/assets/mapgen/reset.svg";
import rotate3dIcon from "@/assets/mapgen/rotate_3d.svg";
import rotateResetIcon from "@/assets/mapgen/rotate_reset.svg";
import smallIcon from "@/assets/mapgen/small.svg";
import tinyIcon from "@/assets/mapgen/tiny.svg";
import undoIcon from "@/assets/mapgen/undo.svg";
import vaillageIcon from "@/assets/mapgen/vaillage.svg";
import valleyIcon from "@/assets/mapgen/valley.svg";
import waterIcon from "@/assets/mapgen/water.svg";
import TownSceneSelectionModal from "@/pages/Mapgen/components/townSceneSelectionModal";
import { MapgenSelect, MapgenTypeEnums } from "@/types";

import "./index.css";

import MapGen from "@/mapgen";

interface IProps {
    mapgenSelect: Partial<MapgenSelect>;
    setMapgenSelect: React.Dispatch<React.SetStateAction<Partial<MapgenSelect>>>;
    onTownSceneChange?: (id: string, title: string) => void;
    mapgen: MapGen | null;
    activeTab?: string;
    terrain?: number;
}

export default function MapgenTool(props: IProps) {
    const { mapgenSelect, setMapgenSelect, onTownSceneChange, mapgen, activeTab, terrain } = props;
    const [townSceneSelectionModalVisible, setTownSceneSelectionModalVisible] =
        useState<boolean>(false);
    const [rotateShow, setRotateShow] = useState<boolean>(true);
    const mapgenSelectHandler = (param: Partial<MapgenSelect>) => {
        if (param.vaillage) {
            setMapgenSelect(param);
        } else {
            setMapgenSelect((preVal) => {
                return {
                    ...DEFAULT_MAPGEN_SELECT,
                    ...preVal,
                    ...param,
                    vaillage: undefined,
                };
            });
        }
    };

    const mapgenVaillageHighlight = useCallback(
        (type: MapgenTypeEnums) => {
            const baseClass = "mr-3  focus-visible:outline-none";
            if (mapgenSelect.vaillage == type) {
                return `${baseClass} highlight`;
            }
            return baseClass;
        },
        [mapgenSelect],
    );

    const mapgenTypeHighlight = useCallback(
        (type: MapgenTypeEnums) => {
            const baseClass = "mr-3  focus-visible:outline-none";
            if (mapgenSelect.type == type && mapgenSelect.vaillage !== MapgenTypeEnums.vaillage) {
                return `${baseClass} highlight`;
            }
            return baseClass;
        },
        [mapgenSelect],
    );

    const mapgenSizeHighlight = useCallback(
        (type: MapgenTypeEnums) => {
            const baseClass = "mr-3 p-0.5 focus-visible:outline-none";
            if (mapgenSelect.size === type && mapgenSelect.vaillage !== MapgenTypeEnums.vaillage) {
                return `${baseClass} highlight`;
            }
            return baseClass;
        },
        [mapgenSelect],
    );

    const popupKeyUp = (e: KeyboardEvent) => {
        if (Object.keys(MAPGEN_TYPE_KEYWORDS).includes(e.key)) {
            mapgenSelectHandler({ type: MAPGEN_TYPE_KEYWORDS[e.key] });
        } else if (Object.keys(MAPGEN_VAILLAGE_KEYWORDS).includes(e.key)) {
            mapgenSelectHandler({ vaillage: MAPGEN_VAILLAGE_KEYWORDS[e.key] });
        } else if (Object.keys(MAPGEN_SIZE_KEYWORDS).includes(e.key)) {
            mapgenSelectHandler({ vaillage: MAPGEN_SIZE_KEYWORDS[e.key] });
        } else if (e.key === "y") {
            clearVillageHandler();
        }
    };

    const clearVillageHandler = () => {
        setMapgenSelect({
            type: MapgenTypeEnums.ocean,
            vaillage: undefined,
            size: MapgenTypeEnums.medium,
        });
    };

    useEffect(() => {
        //监听键盘事件
        document.addEventListener("keyup", popupKeyUp, false);
        return () => {
            //销毁键盘事件
            document.removeEventListener("keyup", popupKeyUp, false);
        };
    });

    const mapgenOperaterRender = [
        {
            id: "pan-map",
            title: "Pan",
            icon: <img src={panIcon} />,
            type: MapgenTypeEnums.pan,
        },
    ];

    const mapgenVaillageRender = [
        {
            id: "vaillage",
            title: (
                <>
                    <div>Add town</div>
                    <ul className="list-disc text-xs text-white/50">
                        <li className="relative left-4 mt-1">Max 3 settlements</li>
                        <li className="relative left-4">
                            Overlap can lead to incorrect generation
                        </li>
                    </ul>
                </>
            ),
            overlayClassName: "[&_.ant-tooltip-inner]:w-[260px]",
            icon: <img src={vaillageIcon} />,
            type: MapgenTypeEnums.vaillage,
            handler: () => {
                setTownSceneSelectionModalVisible(true);
                onTownSceneChange?.("12", "Willowstead");
            },
        },
        {
            id: "deleteVaillage",
            title: "Delete town",
            overlayClassName: "",
            icon: <img src={deleteVaillageIcon} />,
            type: MapgenTypeEnums.deleteVaillage,
        },
    ];

    const mapgenTypeRender = [
        {
            id: "ocean",
            title: "Ocean",
            icon: <img src={oceanIcon} />,
            type: MapgenTypeEnums.ocean,
        },
        {
            id: "shallow",
            title: "Water",
            icon: <img src={waterIcon} />,
            type: MapgenTypeEnums.water,
        },
        {
            id: "valley",
            title: "Valley",
            icon: <img src={valleyIcon} />,
            type: MapgenTypeEnums.valley,
        },
        {
            id: "mountain",
            title: "Mountain",
            icon: <img src={mountainIcon} />,
            type: MapgenTypeEnums.mountain,
        },
    ];

    const mapgenSizeRender = [
        {
            id: "xtiny",
            title: "Brush size:Tiny",
            icon: <img src={tinyIcon} />,
            type: MapgenTypeEnums.xtiny,
        },
        {
            id: "tiny",
            title: "Brush size:Small",
            icon: <img src={smallIcon} />,
            type: MapgenTypeEnums.tiny,
        },
        {
            id: "small",
            title: "Brush size:Medium",
            icon: <img src={mediumIcon} />,
            type: MapgenTypeEnums.small,
        },
        {
            id: "medium",
            title: "Brush size:Large",
            icon: <img src={largeIcon} />,
            type: MapgenTypeEnums.medium,
        },
    ];

    const mapgenHistoryRender = [
        {
            id: "undo",
            title: "undo",
            icon: <img src={undoIcon} />,
            type: MapgenTypeEnums.undo,
        },
        {
            id: "redo",
            title: "redo",
            icon: <img src={redoIcon} />,
            type: MapgenTypeEnums.redo,
        },
        {
            id: "reset",
            title: "reset",
            icon: <img src={resetIcon} />,
            type: MapgenTypeEnums.reset,
        },
    ];

    return (
        <>
            <div className="flex flex-col gap-y-3 pt-3" id="action">
                <div className="mt-4 flex items-center">
                    {mapgenOperaterRender.map((item) => (
                        <Tooltip title={item.title} key={item.type}>
                            <button
                                id={item.id}
                                className={mapgenTypeHighlight(item.type)}
                                onClick={() => {
                                    mapgenSelectHandler({ type: item.type });
                                }}
                            >
                                {item.icon}
                            </button>
                        </Tooltip>
                    ))}
                    <Tooltip title="Rotate">
                        <button
                            className={`mr-2 focus-visible:outline-none ${rotateShow ? "highlight" : ""}`}
                            onClick={() => setRotateShow((preVal) => !preVal)}
                        >
                            <img src={rotate3dIcon} />
                        </button>
                    </Tooltip>
                    <div className="split"></div>
                    {mapgenVaillageRender.map((item) => (
                        <Tooltip
                            title={item.title}
                            key={item.type}
                            overlayClassName={item.overlayClassName}
                            className={activeTab === "text" || terrain !== 0 ? "hidden" : ""}
                        >
                            <button
                                id={item.id}
                                className={mapgenVaillageHighlight(item.type)}
                                onClick={() => {
                                    mapgenSelectHandler({ vaillage: item.type });
                                    item.handler?.();
                                }}
                            >
                                {item.icon}
                            </button>
                        </Tooltip>
                    ))}

                    {activeTab == "random" && terrain === 0 && <div className="split"></div>}

                    {mapgenTypeRender.map((item) => (
                        <Tooltip title={item.title} key={item.type}>
                            <button
                                id={item.id}
                                className={mapgenTypeHighlight(item.type)}
                                onClick={() => {
                                    mapgenSelectHandler({ type: item.type });
                                }}
                            >
                                {item.icon}
                            </button>
                        </Tooltip>
                    ))}
                    {mapgenSizeRender.map((item) => (
                        <Tooltip title={item.title} key={item.type}>
                            <button
                                key={item.type}
                                id={item.id}
                                title={item.title}
                                className={mapgenSizeHighlight(item.type)}
                                onClick={() => {
                                    mapgenSelectHandler({ size: item.type });
                                }}
                            >
                                {item.icon}
                            </button>
                        </Tooltip>
                    ))}
                    <div className="split"></div>
                    {mapgenHistoryRender.map((item) => (
                        <Tooltip title={item.title} key={item.type}>
                            <div>
                                <button
                                    key={item.type}
                                    id={item.id}
                                    title={item.title}
                                    className={mapgenSizeHighlight(item.type)}
                                    // onClick={() => {
                                    //     mapgenSelectHandler({ size: item.type });
                                    // }}
                                >
                                    {item.icon}
                                </button>
                            </div>
                        </Tooltip>
                    ))}
                    <button id="large">
                        <svg viewBox="-50 -50 100 100" color="#ffffff">
                            <circle r="50" />
                        </svg>
                    </button>
                </div>
                <div
                    className={`flex items-center justify-between gap-x-3 ${rotateShow ? "" : "hidden"}`}
                >
                    <div
                        id="sliders-render"
                        className="[&>.silder-container>label>div]:flex [&>.silder-container>label>div]:items-center [&>.silder-container>label]:m-0 [&>.silder-container]:flex-row [&_.silder-container_input]:ml-3 [&_.slider-label>span]:text-nowrap [&_.slider-value]:!w-7 [&_.slider-value]:px-0 [&_.slider-value]:text-center [&_.slider-value]:text-[10px] [&_h3]:hidden [&_input]:!w-20"
                    ></div>
                    <div
                        className="flex h-6 cursor-pointer select-none items-center gap-x-1 rounded-md border border-[#323232] bg-black/80 px-2 py-3.5 text-xs"
                        onClick={() => {
                            mapgen?.getPlugins().get("sliders")?.setAllValue({
                                tilt_deg: 0,
                                rotate_deg: 0,
                            });
                        }}
                    >
                        <img src={rotateResetIcon} /> Reset Map View
                    </div>
                </div>
                <div className="pen flex"></div>
            </div>
            <TownSceneSelectionModal
                visible={townSceneSelectionModalVisible}
                onGenerate={() => {
                    setTownSceneSelectionModalVisible(false);
                }}
                onCancel={() => {
                    setTownSceneSelectionModalVisible(false);
                }}
                onTownSceneChange={(id: string, title: string) => onTownSceneChange?.(id, title)}
            />
        </>
    );
}
