import { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import backIcon from "@/assets/back.png";

// import { generateUSDZ, getVillageLayout } from "@/service/taskService";
import GenerateBtn from '@/components/GenerateBtn';
import TextToMap from "@/components/TextTomap/TextToMap";
import { useFullscreen } from "ahooks";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import fullScreenIcon from "@/assets/fullscreen.svg";
import { generateUSDZ, generateVillageLayout } from '@/service/taskService';
import { settlements } from './data';

function LabTextLayout() {
    const [imageUrl, setImageUrl] = useState<string>('');
    const [generateLoading, setGenerateLoading] = useState(false);
    const mapjson = useRef(null);
    const imageRef = useRef(null);
    const [isFullscreen, { toggleFullscreen }] = useFullscreen(imageRef);
    const [usdzResult, setUsdzResult] = useState<string[]>([]);
    // const [show3D, setShow3D] = useState(false);

    const generateUSDZFile = async (geojson: any) => {
        const generateData = {
            "map_size": 40,
            "upload_usdz": true,
            "upload_config": {
                "name_space": "dev",
                "project_name": "demo",
                "target_path": `test13/demo${new Date().getTime()}.usdz`
            },
            "geojson_content": JSON.stringify(geojson)
        }
        const result = await generateUSDZ(generateData);
        return result.data;
    };


    const generate3DHandler = async () => {
        setGenerateLoading(true);
        const result = await generateUSDZFile(mapjson.current);
        console.log({ result });
        setUsdzResult(result);
        setGenerateLoading(false);
        // setShow3D(true);
    }

    const generateVillageLayoutHandler = async (text: string) => {
        usdzResult.length > 0 && setUsdzResult([]);
        const result = await generateVillageLayout({
            "text": text
        });
        setImageUrl(result.data.image);
        mapjson.current = result.data.mapjson;
        
        console.log({ result });
    }

    return (
        <div className='h-full flex flex-col'>
            <div className="flex items-center pb-5">
                <Link to="/labs" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className=" ml-5">Village Layout</h2>
            </div>
            <div className="flex relative flex-1">
                <div className="flex flex-col gap-4 w-1/2">
                    <TextToMap
                        title="Generate your layout with text"
                        defaultText=""
                        serialNumber={1}
                        clickCallback={generateVillageLayoutHandler}
                        isShowRandom={true}
                        exampleWords={settlements}
                    />
                    <GenerateBtn
                        text="Create 3D preview"
                        isLoading={generateLoading}
                        onClick={generate3DHandler}
                        disabled={generateLoading || !mapjson.current}
                    />
                </div>
                <div className="flex-1 ml-5 flex flex-col items-center" ref={imageRef}>
                    <div className={`flex flex-col p-2 bg-white bg-opacity-10 rounded-md w-full min-h-[460px] ${isFullscreen ? 'flex-1' : ''}`}>
                        <div className="cursor-pointer text-white flex gap-2 justify-end w-full">
                            <img src={isFullscreen ? fullScreenExitIcon : fullScreenIcon} alt="fullscreen" onClick={toggleFullscreen} />
                        </div>
                        <div className='flex-1 flex justify-center items-center'>
                            {imageUrl ?
                                <img src={`data:image/png;base64,${imageUrl}`} alt="tag-to-layout" />
                                : <span className="text-white/20">No image</span>}
                        </div>
                    </div>
                    {usdzResult?.[1] && <div className='w-full flex justify-center mt-4'>
                        <a
                            href={usdzResult?.[1]}
                            target='_blank'
                            className="w-full flex justify-center border border-[#323232] rounded-md px-4 py-2 hover:text-link"
                        >
                            Open 3D City Viewer
                        </a>
                    </div>}
                </div>
                {/* <div className={`absolute inset-0 z-50 w-full h-full flex-col bg-black flex ${show3D ? 'flex' : 'hidden'}`}>
                    <div className='header'>
                        <div className='text-white py-2 px-4 cursor-pointer' onClick={() => setShow3D(false)}>Close</div>
                    </div>
                    <div className='content flex-1 flex justify-center items-center'>
                        <a
                            href={usdzResult?.[1]}
                            target='_blank'
                            className="border border-[#323232] rounded-md px-4 py-2 hover:text-link text-center cursor-pointer"
                        >
                            Open 3D City Viewer
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default LabTextLayout;