// hooks/useMapgenGenerate.ts
import { useState } from "react";
import { message } from "antd";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import {
    CANVAS_DIMENSIONS,
    DEFAULT_MAP_SIZE,
    DEFAULT_MOUNTAIN_HEIGHT,
    MAP_FILE_NAMES,
    TERRAIN_NAMES,
    ZOOM_5000,
} from "../constants";
import type { FileData, MapgenMetadata, TaskResponse } from "../type";
import { useMapgenInfo } from "./useMapgenInfo";
import type MapGen from "@/mapgen";
import param from "@/mapgen/config/index.ts";
import { createTask, revisionTask } from "@/service/taskService";

export const useMapgenGenerate = (
    mapgen: MapGen | null,
    getMapInfo: (taskId: string) => Promise<MapgenMetadata>,
    source?: string,
) => {
    const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false);
    const [baseImg, setBaseImg] = useState<string>();
    const [hideRedMap, setHideRedMap] = useState<boolean>(false);
    const navigate = useNavigate();
    const { saveMapgenInfo } = useMapgenInfo();
    const renderer = mapgen?.getRender();
    const painting = mapgen?.getPainting();
    const plugins = mapgen?.getPlugins();
    const sliders = mapgen?.getMapSlider();
    // let offsetX = 0;
    // let offsetY = 0;

    /**
     * 保存地形高度图
     *
     * @description
     * 该函数执行以下操作：
     * 1. 生成红色高度图
     * 2. 将高度图转换为 Blob 对象
     * 3. 如果地图有缩放，则同时生成裁剪后的高度图
     * 4. 最后恢复原始地图显示
     *
     * @throws {Error} 当渲染器未初始化时抛出错误
     * @returns {Promise<{
     *   blobHeight: Blob,        // 原始高度图的 Blob 对象
     *   blobHeightCut: Blob | undefined  // 裁剪后的高度图 Blob 对象（仅在地图被缩放时存在）
     * }>}
     */
    const saveHeightMapImage = async (): Promise<{
        blobHeight: Blob;
        blobHeightCut: Blob | undefined;
    }> => {
        if (!renderer) {
            throw new Error("Renderer is not initialized");
        }

        // 生成高度图
        renderer.generateHeightMap();

        const blobHeight = await new Promise<Blob>((resolve, reject) => {
            renderer.screenshotCallback = () => {
                renderer.getScreenshotCanvas().toBlob((blob) => {
                    if (!blob) {
                        reject(new Error("Failed to generate height map blob"));
                        return;
                    }
                    resolve(blob);
                });
            };
        });

        generateTestImg(blobHeight, "map_h.png");

        // //恢复原始地图
        renderer.generateOriginalMap();
        const url = URL.createObjectURL(blobHeight);
        const zoom = renderer.getZoom();

        try {
            // 只在有缩放的情况下处理裁剪
            if (zoom && zoom > ZOOM_5000) {
                const renderCanvas = document.querySelector("#mapgen4");
                if (!renderCanvas) {
                    throw new Error("Render canvas not found");
                }

                const blobHeightCut = await saveHeightMapCropImage(
                    url,
                    zoom,
                    renderer.getOffset(),
                    renderCanvas as HTMLElement,
                );
                generateTestImg(blobHeightCut, "map_h_cut.png");

                return { blobHeight, blobHeightCut };
            }

            return { blobHeight, blobHeightCut: undefined };
        } finally {
            // 清理 URL 对象
            URL.revokeObjectURL(url);
        }
    };
    /**
     * 保存裁剪后的地形高度图
     *
     * @param url 原始高度图的 URL
     * @param zoom 缩放比例
     * @param offset 地图偏移量
     * @param renderCanvas 渲染画布
     * @returns {Promise<Blob>} 裁剪后的高度图 Blob 对象
     */
    const saveHeightMapCropImage = (
        url: string,
        zoom: number,
        offset: { x: number; y: number },
        renderCanvas: HTMLElement,
    ): Promise<Blob> => {
        return new Promise((resolve) => {
            const redInfoCanvas = document.createElement("canvas");

            const { WIDTH, HEIGHT, MAP_WIDTH, MAP_HEIGHT, CENTER_POINT } = CANVAS_DIMENSIONS;
            //此处没有根据不同尺寸调整画布大小，固定统一尺寸
            redInfoCanvas.width = WIDTH;
            redInfoCanvas.height = HEIGHT;

            //document.body.appendChild(redInfoCanvas);

            const ctx = redInfoCanvas.getContext("2d");
            const img = new Image();
            img.onload = function () {
                if (!ctx) return;
                const scale = zoom * CANVAS_DIMENSIONS.SCALE_FACTOR;
                const { x, y } = offset;
                const dOffset = ((1 - scale) / 2) * renderCanvas.clientWidth;

                const dSize = renderCanvas.clientWidth * scale;
                let xDOffset = dOffset + (dSize / MAP_WIDTH) * (CENTER_POINT - x);
                let yDOffset = dOffset - (dSize / MAP_HEIGHT) * (CENTER_POINT - y);
                // 旋转画布（角度需要转换为弧度）
                let rotationDegrees = renderer?.getRenderParam().rotate_deg ?? 0;
                if (rotationDegrees !== 0) {
                    // 保存当前画布状态
                    ctx.save();

                    // 将旋转中心点移到画布中心
                    ctx.translate(WIDTH / 2, HEIGHT / 2);

                    ctx.rotate((-rotationDegrees * Math.PI) / 180);
                    xDOffset = xDOffset - WIDTH / 2;
                    yDOffset = yDOffset - HEIGHT / 2;
                }

                ctx?.drawImage(img, xDOffset, yDOffset, dSize, dSize);
                // offsetX = -xDOffset / dSize;
                // offsetY = -yDOffset / dSize;

                redInfoCanvas?.toBlob((blob: Blob | null) => {
                    if (!blob) return;
                    resolve(blob);
                });
            };
            img.src = url;
        });
    };

    /**
     * 保存原始图片
     *
     * @param renderParam 渲染参数，包含倾斜角度和旋转角度
     * @returns {Promise<Blob>} 原始图片的 Blob 对象
     */

    const saveOriginalImage = async (renderParam?: {
        tilt_deg?: number;
        rotate_deg?: number;
    }): Promise<Blob> => {
        return new Promise((resolve) => {
            if (!renderer) return;
            Object.assign(param.render, {
                tilt_deg: renderParam?.tilt_deg ?? 0,
                rotate_deg: renderParam?.rotate_deg ?? 0,
            });

            renderer.updateView(param.render);
            renderer.setScreenshotCallback(() => {
                renderer.getScreenshotCanvas().toBlob((blob: Blob | null) => {
                    if (!blob) return;
                    resolve(blob);
                    generateTestImg(blob, "map.png");
                });
            });
        });
    };

    // window.saveOriginalImage = saveOriginalImage;
    // window.constraints = mapgen?.getPainting().getElevation();

    /**
     * 生成缩略图
     *
     * @param blob 原始图片的 Blob 对象
     * @returns {Promise<Blob>} 缩略图的 Blob 对象
     */
    const generateMiniMap = async (blob: Blob): Promise<Blob> => {
        return new Promise((resolve) => {
            const url = URL.createObjectURL(blob);
            const miniCanvas = document.createElement("canvas");
            // const renderCanvas = document.querySelector("#mapgen4")!;
            miniCanvas.width = 512;
            miniCanvas.height = 512;

            const ctx = miniCanvas.getContext("2d");
            const img = new Image();
            img.onload = function () {
                const { MINI_SIZE } = CANVAS_DIMENSIONS;
                ctx?.drawImage(img, 0, 0, MINI_SIZE, MINI_SIZE);
                miniCanvas?.toBlob((miniBlob: Blob | null) => {
                    if (!miniBlob) return;
                    resolve(miniBlob);
                    generateTestImg(miniBlob);
                });
            };
            img.src = url;
        });
    };

    const loadReader = async (blob: Blob) => {
        return new Promise((reslove) => {
            const url = URL.createObjectURL(blob);
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setBaseImg(url);
                setHideRedMap(true);
                reslove(true);
            };
        });
    };

    /**
     * 创建FormData
     *
     * @param files 包含原始地图、高度图、缩略图和metadata的对象
     * @returns {FormData} 包含所有文件的 FormData 对象
     */
    const createFormData = async (map_size: number): Promise<FormData> => {
        // 保存原始图片
        const { tilt_deg, rotate_deg } = renderer?.getRenderParam();
        const originalMap: Blob = await saveOriginalImage({ tilt_deg, rotate_deg });
        // const url = URL.createObjectURL(originalMap);

        await loadReader(originalMap);

        // 保存高度图
        const { blobHeight: heightMap, blobHeightCut: heightMapCut } = await saveHeightMapImage();

        // 生成缩略图
        const miniMap: Blob = await generateMiniMap(originalMap);

        // return;

        // data.json 数据
        const mountain_height = Math.max(
            renderer?.getMountainHeight() ?? DEFAULT_MOUNTAIN_HEIGHT,
            DEFAULT_MOUNTAIN_HEIGHT,
        );

        const vaillage = painting?.vaillageManager?.getVaillage();
        if (vaillage.length > 0) {
            vaillage.forEach((v: any) => {
                const result = renderer?.mapToScreen([v.x * 1000, v.y * 1000], [1, 1]);
                if (result) {
                    [v.x, v.y] = result;
                }
            });
        }

        const metadata: MapgenMetadata = {
            map_type: 2,
            map_zoom_rate: parseFloat((map_size / DEFAULT_MAP_SIZE).toFixed(2)),
            map_style_terrain: renderer?.drawer?.getTerrain() ?? 0,
            map_size,
            mountain_height,
            constraints: painting?.getPaintingElevation() || {},
            sliders: plugins?.get("sliders")?.getAllValue(),
            positon: renderer?.getOffset(),
            villages: vaillage,
        };

        // return;
        generateJsonFile(new Blob([JSON.stringify(metadata)], { type: "application/json" }));

        const formData = new FormData();
        const requiredFiles: FileData[] = [
            { blob: originalMap, name: MAP_FILE_NAMES.ORIGINAL },
            { blob: heightMap, name: MAP_FILE_NAMES.HEIGHT },
            { blob: miniMap, name: MAP_FILE_NAMES.MINI },
            {
                blob: new Blob([JSON.stringify(metadata)], {
                    type: "application/json",
                }),
                name: MAP_FILE_NAMES.METADATA,
            },
        ];

        const optionalFiles: FileData[] = heightMapCut
            ? [{ blob: heightMapCut, name: MAP_FILE_NAMES.HEIGHT_CUT }]
            : [];

        [...requiredFiles, ...optionalFiles].forEach(({ blob, name }) => {
            formData.append("files", blob, name);
        });

        return formData;
    };

    /**
     * 处理任务提交
     *
     * @param formData 包含所有文件的 FormData 对象
     * @param taskId 任务 ID
     * @param isRevision 是否为修正任务
     * @returns {Promise<TaskResponse>} 任务提交结果
     */
    const handleTaskSubmission = async (
        formData: FormData,
        taskId: string | null,
        isRevision: boolean,
    ): Promise<TaskResponse> => {
        return isRevision && taskId
            ? await revisionTask(formData, taskId)
            : await createTask(formData, 2);
    };

    /**
     * 生成地图
     *
     * @param taskId 任务 ID
     */
    const generate = async (
        taskId: string | null,
        vaillage: string | null,
        clearLocal?: () => void,
    ) => {
        try {
            setIsGenerateLoading(true);
            // 根据修正条件，重置旋转角度
            renderer?.updateView(param.render);
            renderer?.resetRotationAngles();

            const map_size = (sliders?.valueAsNumber ?? 1) * 1000 || DEFAULT_MAP_SIZE;
            const formData = await createFormData(map_size);
            // 提交任务
            let isRevision = !!taskId;
            const response = await handleTaskSubmission(formData, taskId, isRevision);
            const { status, data } = response;

            if (status === 200 && data?.task_id) {
                await handleSuccessfulTaskResponse(response, map_size, isRevision, vaillage);
            } else {
                if (isRevision) {
                    clearLocal?.();
                    isRevision = false;
                    taskId = null;
                    const response = await handleTaskSubmission(formData, taskId, isRevision);
                    const { status, data } = response;
                    if (status === 200 && data?.task_id) {
                        await handleSuccessfulTaskResponse(response, map_size, isRevision, vaillage);
                    }
                }
            }
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.status === 403) {
                    message.error("Permission denied");
                }
            }
            message.error("Generate failed");
        } finally {
            setIsGenerateLoading(false);
            setHideRedMap(false);
        }
    };

    const handleSuccessfulTaskResponse = async (
        response: TaskResponse,
        map_size: number,
        isRevision: boolean,
        vaillage: string | null,
    ) => {
        const { task_id } = response.data;
        let combinedData: Record<number, number> = {};

        const mapInfo = await getMapInfo(task_id);
        const { constraints } = mapInfo;
        const paintingElevation = painting?.getPaintingElevation() || {};

        Object.entries(constraints || {}).forEach(([index, value]) => {
            combinedData[Number(index)] = value as number;
        });

        Object.entries(paintingElevation).forEach(([index, value]) => {
            combinedData[Number(index)] = value;
        });

        saveMapgenInfo({
            task_id,
            size: map_size,
            terrain: renderer?.drawer?.getTerrain() ?? 0,
            data: combinedData,
            sliders: plugins?.get("sliders")?.getAllValue(),
            position: renderer?.getOffset(),
            seed: painting?.getSeed(),
        });

        const biome = TERRAIN_NAMES[renderer?.drawer?.getTerrain() ?? 0];
        navigate("/home/preview", {
            replace: true,
            state: {
                param,
                task_id,
                from: "mapgen",
                source: source,
                size: map_size,
                biome: biome,
                isRevision,
                villageType: biome === "Forest Wetland" ? vaillage : "",
            },
        });
    };

    /**
     * 检查并处理地图旋转状态
     *
     * @description
     * 该函数检查地图是否有旋转角度，如果有：
     * 1. 保存当前带旋转角度的地图
     * 2. 保存重置旋转角度后的地图
     * 3. 恢复原始旋转角度
     *
     * @returns {Promise<{
     *   needConfirm: boolean,    // 是否需要用户确认（当有旋转角度时为 true）
     *   resetImage?: string      // 重置旋转角度后的地图图片 URL
     * }>}
     */
    const checkAndHandleMapRotation = async (setShowGenWarning: (show: boolean) => void) => {
        const render = mapgen?.getRender();
        const zoom = render?.getZoom();

        //零时修改

        if (zoom && zoom < 0.416) {
            setShowGenWarning(true);
            setIsGenerateLoading(false);
            return { noNeedGenerate: true };
        }
        const isRotationAnglesReset = render?.isRotationAnglesReset();

        if (isRotationAnglesReset) {
            const { tilt_deg, rotate_deg } = render?.getRenderParam();

            // 保存原始图像（带角度）
            const blob = await saveOriginalImage({ tilt_deg, rotate_deg });
            const url = URL.createObjectURL(blob);
            setBaseImg(url);
            setHideRedMap(true);

            // 保存重置后的图像
            const blobReset = await saveOriginalImage();
            const urlReset = URL.createObjectURL(blobReset);

            // 更新渲染参数
            Object.assign(param.render, { tilt_deg, rotate_deg });
            render?.updateView(param.render);

            return {
                needConfirm: true,
                resetImage: urlReset,
            };
        }
        return { needConfirm: false };
    };

    const generateTestImg = (blob: Blob, name?: string) => {
        return;
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = name ?? "screenshot.png"; // 设置下载的文件名
        link.click();
        URL.revokeObjectURL(url);
    };

    const generateJsonFile = (blob: Blob, name?: string) => {
        return;
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = name ?? "data.json"; // 设置下载的文件名
        link.click();
        URL.revokeObjectURL(url);
    };

    return {
        isGenerateLoading,
        baseImg,
        hideRedMap,
        generate,
        saveOriginalImage,
        setBaseImg,
        setHideRedMap,
        checkAndHandleMapRotation,
    };
};
