/*
初始连接:
用户A                    信令服务器                Unity服务器
  |                          |                        |
  |------ WebSocket连接 ---->|                        |
  |                          |                        |
  |---- createConnection --->|---- 通知Unity -------->|
  |                          |                        |
  
WebRTC协商过程:
用户A                    信令服务器                Unity服务器
  |                          |                        |
  |                          |<----- Offer ---------|
  |<------ Offer ------------|                        |
  |                          |                        |
  |------- Answer --------->|------- Answer -------->|
  |                          |                        |
  |<---- ICE Candidates --->|<--- ICE Candidates --->|

  媒体流传输:
  用户A                    Unity服务器
  |                          |
  |<---- 视频流(WebRTC) -----|
  |                          |
  |---- 输入数据(DataChannel)->|
  |                          |
*/

// Add interface for callback configuration
interface UnityStreamCallbacks {
    onLoss?: (status: number) => void;
    onSend?: (data: any) => void;
    onConnected?: (content: string) => void;
}

export class UnityStreamReceiver extends EventTarget {
    private pc!: RTCPeerConnection;
    private ws!: WebSocket;
    private iceServers: RTCIceServer[] | null = null;
    // Add callback properties
    private onLoss: ((status: number) => void) | null = null;
    private onSend: ((data: any) => void) | null = null;
    private onConnected: ((content: string) => void) | null = null;
    private connectionId!: string;
    private makingOffer: boolean = false;
    private waitingAnswer: boolean = false;
    private ignoreOffer: boolean = false;
    // private srdAnswerPending: boolean = false;
    private polite: boolean = true; // 默认为 true，在收到 connect 响应时可能会更新
    private isWsOpen: boolean = false;
    private readonly resendInterval: number = 5000;
    private reconnectAttempts: number = 0;
    private readonly maxReconnectAttempts: number = 3;
    private pendingCandidates: RTCIceCandidate[] = [];
    private VideoDelayTimeThreshod: number = 30000;
    private reconnectDelay: number = 2000;
    // 视频参数
    // private lastProcessedOffer: string = '';
    // private lastOfferTime: number = 0;
    private isLoopRunning: boolean = false;
    private maxBitrate: number = 5000000;
    private minBitrate: number = 3000000;
    // private resolution: string = "1920x1080";
    private resolution: string = "1280x720";

    private framerate: number = 20;
    private editeSDP: boolean = true;
    private defaultConfig: RTCConfiguration = {
        iceTransportPolicy: "all",
        iceCandidatePoolSize: 20,
        bundlePolicy: "max-bundle", // 只发送一个流（视频/音频），避免多条流的冲突
        rtcpMuxPolicy: "require", // 强制启用 RTCP Mux，允许多个媒体流共用一个 RTP 连接
        iceServers: [],
    };
    private unityLostCheckTimer: NodeJS.Timeout | null = null;
    private unityLostMessageTimer: NodeJS.Timeout | null = null;
    // private unityLostMessageTestTimer: NodeJS.Timeout | null = null;


    // debug
    private debug: boolean = true;
    private detail: boolean = false;
    private debugVideoStats: boolean = true;

    // muted
    private mutedTimes: number = 0;

    constructor(
        private wsUrl: string,
        private readonly unityId: string,
        private readonly remoteVideo: HTMLVideoElement,
        callbacks: UnityStreamCallbacks = {},
    ) {
        super();
        // Initialize callbacks
        this.onLoss = callbacks.onLoss || null;
        this.onSend = callbacks.onSend || null;
        this.onConnected = callbacks.onConnected || null;
        this.initialize();
        // Start UnityLost message interval for testing
        // this.startUnityLostMessageInterval();
    }

    private async initialize(): Promise<void> {
        // 初始化 WebSocket 连接
        // const protocol = location.protocol === "https:" ? "wss:" : "ws:";
        console.log("wsUrl: ", this.wsUrl);
        this.wsUrl = `${this.wsUrl}/?target=${this.unityId}`;
        console.log("Target UnityId: ", this.unityId);
        // this.unityId = "beijing01-unity-dev";
        this.ws = new WebSocket(this.wsUrl);
        this.initWebsocket();
        // 等待 WebSocket 连接建立
        await this.waitForWsOpen();
        // 初始化 PeerConnection

        await this.waitForIceServers();
        console.log("iceServers: ", this.iceServers);
        this.initializePeerConnection();
        // 创建连接
        this.connectionId = this.generateConnectionId();
        this.logWithTimestamp("-------- generateConnectionId:", this.connectionId);
        this.createConnection();
        // 开始重发 offer 的循环
        this.startResendOfferLoop();
    }

    private async initWebsocket(): Promise<void> {
        this.ws.onopen = this.onWsOpen.bind(this);
        this.ws.onmessage = this.onWsMessage.bind(this);
        this.ws.onclose = () => {
            this.isWsOpen = false;
        };
    }

    private async waitForIceServers(): Promise<void> {
        while (!this.iceServers) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
    }

    private logWithTimestamp(message: string, ...args: any[]): void {
        const timestamp = new Date().toISOString().split("T")[1].split(".")[0];
        console.log(`[ ${timestamp}] ${message}`, ...args);
    }

    private initializePeerConnection(): void {
        if (this.iceServers) {
            this.defaultConfig.iceServers = this.defaultConfig.iceServers?.concat(this.iceServers);
        }
        this.pc = new RTCPeerConnection(this.defaultConfig);
        this.logWithTimestamp("PeerConnection initialized with config:", this.defaultConfig);

        // 确保在添加 ontrack 之前添加 transceivers
        this.pc.addTransceiver("video", {
            direction: "recvonly",
        });
        this.pc.addTransceiver("audio", { direction: "recvonly" });

        this.pc.ontrack = (e: RTCTrackEvent) => {
            this.logWithTimestamp("=====  Track received:\n");
            if (e.track.kind === "video" && this.remoteVideo) {
                this.logWithTimestamp("Setting up video element");

                if (e.streams && e.streams[0]) {
                    this.remoteVideo.srcObject = e.streams[0];
                } else {
                    const stream = new MediaStream([e.track]);
                    this.remoteVideo.srcObject = stream;
                }

                // 添加加载和错误事件处理
                this.remoteVideo.onloadedmetadata = () => {
                    this.logWithTimestamp("Video loadedmetadata event fired");
                    this.remoteVideo
                        .play()
                        .then(() => this.logWithTimestamp("Video playback started"))
                        .catch((err) => this.logWithTimestamp("Video playback failed:", err));
                };

                this.remoteVideo.onerror = (err) => {
                    this.logWithTimestamp("Video element error:", err);
                };

                this.remoteVideo.style.display = "block";
                this.onConnected && this.onConnected("");
                this.mutedTimes = 0;
                // Clear the Unity lost check when video track is received
                this.clearUnityLostCheck();
                // 监听轨道状态
                e.track.onended = () => {
                    this.logWithTimestamp("Video track ended");
                };
                e.track.onmute = () => {
                    this.mutedTimes++;
                    this.logWithTimestamp("Video track muted");
                    if (this.mutedTimes > 5) {
                        this.logWithTimestamp("Video track muted too many times, reconnecting...");
                        this.reconnect();
                    }
                };
                e.track.onunmute = () => {
                    this.logWithTimestamp("Video track unmuted");
                };

                if (this.debug && this.debugVideoStats) {
                    // 添加统计信息监控
                    let lastBytesReceived = 0;
                    let lastTimestamp = 0;
                    const statsInterval = setInterval(() => {
                        this.pc.getStats(e.track).then((stats) => {
                            stats.forEach((report) => {
                                if (report.type === "inbound-rtp" && report.kind === "video") {
                                    const currentBytes = report.bytesReceived;
                                    const currentTimestamp = report.timestamp;

                                    const bitrate =
                                        lastTimestamp && lastBytesReceived
                                            ? (8 * (currentBytes - lastBytesReceived)) /
                                              ((currentTimestamp - lastTimestamp) / 1000)
                                            : 0;

                                    lastBytesReceived = currentBytes;
                                    lastTimestamp = currentTimestamp;

                                    this.logWithTimestamp(
                                        `Video Stats:\n` +
                                            `Resolution: ${report.frameWidth}x${report.frameHeight}\n` +
                                            `Bitrate: ${(bitrate / 1000000).toFixed(2)} Mbps\n` +
                                            `Total Bytes Received: ${(currentBytes / 1024 / 1024).toFixed(2)} MB\n` +
                                            `Packet Loss Rate: ${((report.packetsLost / (report.packetsReceived + report.packetsLost)) * 100).toFixed(2)}%\n` +
                                            `Jitter: ${report.jitter?.toFixed(3)}ms\n`,
                                    );
                                }
                            });
                        });
                    }, 5000);
                    e.track.onended = () => clearInterval(statsInterval);
                }
            }
        };

        if (this.debug && this.detail) {
            // 1. 添加更详细的连接状态监控
            this.pc.onconnectionstatechange = () => {
                this.logWithTimestamp(`Connection state changed to: ${this.pc.connectionState}`, {
                    iceConnectionState: this.pc.iceConnectionState,
                    iceGatheringState: this.pc.iceGatheringState,
                    signalingState: this.pc.signalingState,
                });
            };
        }

        if (this.debug && this.detail) {
            // 2. 增强 ICE 连接状态监控
            this.pc.oniceconnectionstatechange = () => {
                this.logWithTimestamp(
                    `ICE connection state changed to: ${this.pc.iceConnectionState}`,
                    {
                        connectionState: this.pc.connectionState,
                        iceGatheringState: this.pc.iceGatheringState,
                    },
                );

                if (
                    this.pc.iceConnectionState === "disconnected" ||
                    this.pc.iceConnectionState === "failed"
                ) {
                    this.logWithTimestamp("Connection failure details:", {
                        iceConnectionState: this.pc.iceConnectionState,
                        iceGatheringState: this.pc.iceGatheringState,
                        connectionState: this.pc.connectionState,
                        signalingState: this.pc.signalingState,
                    });

                    this.pc
                        .getStats()
                        .then((stats) => {
                            stats.forEach((report) => {
                                // 检查传输层的问题
                                if (report.type === "transport") {
                                    this.logWithTimestamp("Transport Status:", {
                                        bytesReceived: report.bytesReceived,
                                        bytesSent: report.bytesSent,
                                        dtlsState: report.dtlsState,
                                        dtlsCipher: report.dtlsCipher,
                                        dtlsRole: report.dtlsRole,
                                        selectedCandidatePairChanges:
                                            report.selectedCandidatePairChanges,
                                        selectedCandidatePairId: report.selectedCandidatePairId,
                                        localCertificateId: report.localCertificateId,
                                        remoteCertificateId: report.remoteCertificateId,
                                    });

                                    // 如果有远程证书ID，也查找远程证书详情
                                    if (report.remoteCertificateId) {
                                        stats.forEach((r) => {
                                            if (
                                                r.type === "certificate" &&
                                                r.id === report.remoteCertificateId
                                            ) {
                                                this.logWithTimestamp("Remote Certificate:", {
                                                    fingerprint: r.fingerprint,
                                                    fingerprintAlgorithm: r.fingerprintAlgorithm,
                                                    base64Certificate: r.base64Certificate,
                                                });
                                            }
                                        });
                                    }

                                    // 检查是否存在证书不匹配
                                    if (report.dtlsState === "failed") {
                                        this.logWithTimestamp("DTLS Handshake Failed:", {
                                            localFingerprint: report.localCertificateFingerprint,
                                            remoteFingerprint: report.remoteCertificateFingerprint,
                                            dtlsRole: report.dtlsRole,
                                            dtlsCipher: report.dtlsCipher,
                                        });
                                    }
                                }

                                // 检查 ICE 连接的具体问题
                                if (report.type === "candidate-pair" && report.state === "failed") {
                                    this.logWithTimestamp("Failed Candidate Pair:", {
                                        localCandidateId: report.localCandidateId,
                                        remoteCandidateId: report.remoteCandidateId,
                                        transportId: report.transportId,
                                        priority: report.priority,
                                        nominated: report.nominated,
                                        writable: report.writable,
                                        bytesSent: report.bytesSent,
                                        bytesReceived: report.bytesReceived,
                                        lastPacketSentTimestamp: report.lastPacketSentTimestamp,
                                        lastPacketReceivedTimestamp:
                                            report.lastPacketReceivedTimestamp,
                                    });
                                }
                            });
                        })
                        .catch((err) => {
                            this.logWithTimestamp("Error getting stats:", err);
                        });
                }

                if (this.pc.iceConnectionState === "connected") {
                    this.logWithTimestamp("ICE Connection established, checking candidates...");
                    // 当ICE连接建立时，获取使用的候选者信息
                    this.pc
                        .getStats()
                        .then((stats) => {
                            let foundSelectedPair = false;
                            stats.forEach((report) => {
                                if (
                                    report.type === "candidate-pair" &&
                                    report.state === "succeeded"
                                ) {
                                    foundSelectedPair = true;
                                    this.logWithTimestamp("Found selected candidate pair:", report);
                                    // 查找对应的本地和远程候选者
                                    stats.forEach((r) => {
                                        if (
                                            r.type === "local-candidate" &&
                                            r.id === report.localCandidateId
                                        ) {
                                            this.logWithTimestamp("Selected Local ICE Candidate:", {
                                                id: r.id,
                                                type: r.candidateType,
                                                protocol: r.protocol,
                                                address: r.address,
                                                port: r.port,
                                            });
                                        }
                                        if (
                                            r.type === "remote-candidate" &&
                                            r.id === report.remoteCandidateId
                                        ) {
                                            this.logWithTimestamp(
                                                "Selected Remote ICE Candidate:",
                                                {
                                                    id: r.id,
                                                    type: r.candidateType,
                                                    protocol: r.protocol,
                                                    address: r.address,
                                                    port: r.port,
                                                },
                                            );
                                        }
                                    });
                                }
                            });
                            if (!foundSelectedPair) {
                                this.logWithTimestamp("No selected candidate pair found in stats");
                            }
                        })
                        .catch((err) => {
                            this.logWithTimestamp("Error getting stats:", err);
                        });
                }
            };
        }

        if (this.debug && this.detail) {
            // 3. 添加 ICE 候选者收集状态监控
            this.pc.onicegatheringstatechange = () => {
                this.logWithTimestamp(
                    `ICE gathering state changed to: ${this.pc.iceGatheringState}`,
                );
            };
        }

        if (this.debug && this.detail) {
            // 4. 添加 ICE 候选者处理监控
            this.pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
                if (event.candidate) {
                    const candidate = event.candidate.candidate;
                    // 打印 ICE 候选者类型
                    if (candidate.includes("typ relay")) {
                        this.logWithTimestamp(" ICE Candidate: Using TURN relay server");
                    } else if (candidate.includes("typ srflx")) {
                        this.logWithTimestamp(" ICE Candidate: Using STUN (Server Reflexive)");
                    } else if (candidate.includes("typ host")) {
                        this.logWithTimestamp(" ICE Candidate: Using local network (host)");
                    }

                    // 打印传输协议
                    if (event.candidate.protocol === "udp") {
                        this.logWithTimestamp(" Transport Protocol: Using UDP");
                    } else if (event.candidate.protocol === "tcp") {
                        this.logWithTimestamp(" Transport Protocol: Using TCP");
                    }

                    // 打印完整的候选者信息
                    this.logWithTimestamp("ICE Candidate Details:", {
                        type: event.candidate.type,
                        protocol: event.candidate.protocol,
                        address: event.candidate.address,
                        port: event.candidate.port,
                        priority: event.candidate.priority,
                        foundation: event.candidate.foundation,
                    });

                    const data = {
                        candidate: candidate,
                        sdpMLineIndex: event.candidate.sdpMLineIndex,
                        sdpMid: event.candidate.sdpMid,
                        connectionId: this.connectionId,
                    };

                    const sendJson = JSON.stringify({
                        type: "candidate",
                        from: this.connectionId,
                        data: data,
                    });
                    this.ws.send(sendJson);
                } else {
                    this.logWithTimestamp("ICE candidate gathering completed");
                }
            };
        }

        if (this.debug && this.detail) {
            // 监听信令状态变化
            this.pc.onsignalingstatechange = () => {
                this.logWithTimestamp(
                    `Signaling state changed to: ${this.pc.signalingState.toString()}`,
                    {
                        connectionState: this.pc.connectionState,
                        iceState: this.pc.iceConnectionState,
                    },
                );
            };
        }

        if (this.debug && this.detail) {
            // 添加 SCTP 传输状态监控
            const sctp = this.pc?.sctp;
            if (sctp) {
                sctp.onstatechange = () => {
                    this.logWithTimestamp(`SCTP state changed to: ${sctp?.state ?? "unknown"}`, {
                        maxChannels: sctp?.maxChannels ?? 0,
                        maxMessageSize: sctp?.maxMessageSize ?? 0,
                    });
                };
            }
        }
    }

    private async onWsOpen(): Promise<void> {
        this.isWsOpen = true;
    }

    private async onWsMessage(event: MessageEvent): Promise<void> {
        const msg = JSON.parse(event.data);
        this.logWithTimestamp(`WebSocket 收到消息:`, msg.type);
        if (msg.type === "error") {
            console.log("-----------  error received");
            console.log(msg);
            return;
        }

        // if (msg.type === 'offer') {
        //   this.lastOfferTime = msg.datetime || Date.now();
        // }

        switch (msg.type) {
            case "config":
                this.iceServers = msg.data;
                console.log("-----------  收到配置 iceServers", this.iceServers);
                break;
            case "connect":
                this.logWithTimestamp(`Connect message received, polite mode: ${msg.polite}`);
                this.polite = msg.polite;
                await this.startNegotiation();
                break;
            case "answer":
                console.log("-----------  answer received");
                console.log("-----------  Unity 的 sdp");
                await this.handleRemoteDescription(
                    new RTCSessionDescription({
                        type: "answer",
                        sdp: msg.data.sdp,
                    }),
                );
                // 处理完answer后处理之前缓存的candidates
                while (this.pendingCandidates.length) {
                    const candidate = this.pendingCandidates.shift();
                    await this.pc.addIceCandidate(candidate);
                }
                break;
            case "offer":
                if (this.debug && this.detail) {
                    this.logWithTimestamp(`Offer received:`, msg.data);
                }
                await this.handleRemoteDescription(
                    new RTCSessionDescription({
                        type: "offer",
                        sdp: msg.data.sdp,
                    }),
                );
                break;
            case "candidate":
                const candidate = new RTCIceCandidate({
                    candidate: msg.data.candidate,
                    sdpMLineIndex: msg.data.sdpMLineIndex,
                    sdpMid: msg.data.sdpMid,
                });

                if (this.pc.remoteDescription) {
                    await this.pc.addIceCandidate(candidate);
                } else {
                    // 缓存candidate
                    this.pendingCandidates.push(candidate);
                }
                break;
            case "disconnect":
                this.logWithTimestamp("----------- unity disconnect received");
                this.onSend && this.onSend({ command: "UnityLost", streamerId: this.unityId });
                this.logWithTimestamp("UnityLost notification sent in disconnect");

                // Wait 2 seconds before attempting to reconnect
                setTimeout(() => {
                    this.reconnect();
                }, 2000);
                break;

            case "updateUnityWs":
                this.logWithTimestamp("----------- updateUnityWs received");
                this.reconnect();
                break;
        }
    }

    private async handleRemoteDescription(description: RTCSessionDescription): Promise<void> {
        try {
            // 1. 更严格的状态检查
            const isStable = this.pc.signalingState === "stable";
            const isLocalOffer = this.pc.signalingState === "have-local-offer";
            const isRemoteOffer = this.pc.signalingState === "have-remote-offer";
            if (this.debug && this.detail) {
                this.logWithTimestamp("Handling remote description:", {
                    type: description.type,
                    currentState: this.pc.signalingState,
                    isStable,
                    isLocalOffer,
                    makingOffer: this.makingOffer,
                    polite: this.polite,
                });
            }

            // 2. 改进冲突检测逻辑
            this.ignoreOffer =
                description.type === "offer" &&
                ((!this.polite && (this.makingOffer || !isStable)) || isRemoteOffer);

            if (this.ignoreOffer) {
                this.logWithTimestamp("Ignoring offer due to collision or wrong state");
                return;
            }

            // 3. 处理 answer 时的状态检查
            if (description.type === "answer") {
                if (!isLocalOffer) {
                    this.logWithTimestamp("Received answer in wrong state, ignoring");
                    return;
                }
                this.waitingAnswer = false;
            }

            // 4. 处理 offer 时的状态检查
            if (description.type === "offer") {
                if (isRemoteOffer) {
                    this.logWithTimestamp("Already have remote offer, rolling back");
                    await this.pc.setLocalDescription({ type: "rollback" });
                }
                this.waitingAnswer = false;
            }

            // 5. 设置远程描述
            console.log("-----------  设置远程描述");
            // this.srdAnswerPending = description.type === 'answer';
            await this.pc.setRemoteDescription(description);
            // this.srdAnswerPending = false;

            // 6. 如果是 offer，创建并发送 answer
            if (description.type === "offer") {
                await this.pc.setLocalDescription();
                let sdp = this.pc.localDescription?.sdp;

                if (sdp && this.editeSDP) {
                    // sdp = this.modifySDPForHighQuality(sdp);
                    sdp = sdp.replace(/m=video.*\r\n/g, (match) => {
                        return (
                            match +
                            `b=AS:${this.maxBitrate}\r\n` +
                            `b=TIAS:${this.minBitrate}\r\n` +
                            `a=framerate:${this.framerate}\r\n` +
                            `a=resolution:${this.resolution}\r\n`
                        );
                    });
                    this.logWithTimestamp(" Sending Answer");
                    this.sendWsMessage({
                        type: "answer",
                        from: this.connectionId,
                        data: {
                            sdp: sdp,
                            connectionId: this.connectionId,
                        },
                    });
                }
            }
        } catch (e) {
            this.logWithTimestamp("Error in handleRemoteDescription:", e);
            // 7. 错误恢复
            if (this.pc.signalingState !== "stable") {
                try {
                    await this.pc.setLocalDescription({ type: "rollback" });
                    this.logWithTimestamp("Rolled back to stable state");
                } catch (rollbackError) {
                    this.logWithTimestamp("Rollback failed:", rollbackError);
                }
            }
        }
    }

    private async waitForWsOpen(): Promise<void> {
        while (!this.isWsOpen) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
    }

    private createConnection(): void {
        this.sendWsMessage({
            type: "connect",
            connectionId: this.connectionId,
        });
    }

    private async startResendOfferLoop(): Promise<void> {
        this.isLoopRunning = true;
        let consecutiveFailures = 0;
        const maxFailures = 3;

        while (this.isLoopRunning) {
            try {
                if (
                    this.waitingAnswer &&
                    this.pc &&
                    !this.ignoreOffer &&
                    this.pc.signalingState === "have-local-offer"
                ) {
                    // 只在连接状态正常时重发
                    if (
                        this.pc.connectionState !== "failed" &&
                        this.pc.iceConnectionState !== "failed"
                    ) {
                        // 修改 sdp 设置最大最小码率
                        let sdp = this.pc.localDescription?.sdp;
                        this.logWithTimestamp("Resending offer");

                        if (sdp && this.editeSDP) {
                            sdp = sdp.replace(/m=video.*\r\n/g, (match) => {
                                return (
                                    match +
                                    `b=AS:${this.maxBitrate}\r\n` +
                                    `b=TIAS:${this.minBitrate}\r\n` +
                                    `a=framerate:${this.framerate}\r\n` +
                                    `a=resolution:${this.resolution}\r\n`
                                );
                            });
                        }
                        this.sendWsMessage({
                            type: "offer",
                            from: this.connectionId,
                            data: {
                                sdp: this.pc.localDescription?.sdp,
                                connectionId: this.connectionId,
                            },
                        });
                        consecutiveFailures = 0;
                    } else {
                        consecutiveFailures++;
                    }

                    // 如果连续失败次数过多，触发重连
                    if (consecutiveFailures >= maxFailures) {
                        this.logWithTimestamp(
                            "Too many consecutive failures, initiating reconnection",
                        );
                        await this.handleConnectionFailure();
                        consecutiveFailures = 0;
                    }
                }
            } catch (e) {
                this.logWithTimestamp("Error in resend loop:", e);
                consecutiveFailures++;
            }

            await new Promise((resolve) => setTimeout(resolve, this.resendInterval));
        }
    }
    private reconnectWebSocket(): void {
        this.ws.close();
        this.ws = new WebSocket(this.wsUrl);
    }

    private sendWsMessage(msg: any): void {
        if (this.isWsOpen) {
            this.logWithTimestamp("Sending WebSocket message:", msg.type);
            this.ws.send(JSON.stringify(msg));
            //   this.onSend && this.onSend(msg);
        } else {
            this.logWithTimestamp("WebSocket not open, message not sent:", msg.type);
            // this.reconnectWebSocket();
        }
    }

    private generateConnectionId(): string {
        // Start the Unity lost check timer when generating connection ID
        this.startUnityLostCheck();
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    private startUnityLostCheck(): void {
        // Clear any existing timer
        this.logWithTimestamp("startUnityLostCheck ...");
        if (this.unityLostCheckTimer) {
            clearTimeout(this.unityLostCheckTimer);
        }
        // Start 30s timer
        this.unityLostCheckTimer = setTimeout(() => {
            this.logWithTimestamp(
                "Unity connection check failed - sending UnityLost notification and attempting to reconnect",
            );
            this.onSend && this.onSend({ command: "UnityLost", streamerId: this.unityId });
            this.logWithTimestamp("UnityLost notification sent in startUnityLostCheck");

            // Wait 2 seconds before attempting to reconnect
            setTimeout(() => {
                this.reconnect();
            }, this.reconnectDelay);
        }, this.VideoDelayTimeThreshod); // 30s timeout
    }

    private clearUnityLostCheck(): void {
        if (this.unityLostCheckTimer) {
            this.logWithTimestamp("clearUnityLostCheck ...");
            clearTimeout(this.unityLostCheckTimer);
            this.unityLostCheckTimer = null;
        }
        if (this.unityLostMessageTimer) {
            clearInterval(this.unityLostMessageTimer);
            this.unityLostMessageTimer = null;
        }
        // this.unityLostRetryCount = 0;
    }

    public async destroy(): Promise<void> {
        this.logWithTimestamp("Stopping UnityStreamReceiver");
        if (this.pc) {
            this.pc.close();
            this.logWithTimestamp("PeerConnection closed");
        }
        if (this.ws) {
            this.ws.close();
            this.logWithTimestamp("WebSocket connection closed");
        }
        this.remoteVideo.srcObject = null;
        this.logWithTimestamp("Video stream cleared");
        this.iceServers = [];
        this.clearUnityLostCheck();
        this.mutedTimes = 0;
        this.reconnectAttempts = 0;
        this.isLoopRunning = false;
        this.polite = true;
        this.reconnectAttempts = 0;
        this.mutedTimes = 0;
    }

    // 新增方法：主动开始协商
    private async startNegotiation(): Promise<void> {
        try {
            if (this.makingOffer) {
                this.logWithTimestamp("Already making offer, skipping negotiation");
                return;
            }

            this.makingOffer = true;

            // 检查并等待状态稳定
            if (this.pc.signalingState !== "stable") {
                this.logWithTimestamp("Waiting for signaling state to stabilize");
                await new Promise((resolve) => {
                    const checkState = () => {
                        if (this.pc.signalingState === "stable") {
                            resolve(undefined);
                        } else {
                            setTimeout(checkState, 100);
                        }
                    };
                    checkState();
                });
            }

            const offer = await this.pc.createOffer({
                offerToReceiveVideo: true,
                offerToReceiveAudio: true,
            });

            if (this.pc.signalingState === "stable") {
                await this.pc.setLocalDescription(offer);
                this.waitingAnswer = true;
                // const modify_sdp = this.modifySDPForHighQuality(this.pc.localDescription?.sdp || '');
                this.sendWsMessage({
                    type: "offer",
                    from: this.connectionId,
                    data: {
                        sdp: this.pc.localDescription?.sdp,
                        connectionId: this.connectionId,
                    },
                });
            } else {
                this.logWithTimestamp("Signaling state changed during negotiation, aborting");
            }
        } catch (e) {
            this.logWithTimestamp("Error in startNegotiation:", e);
        } finally {
            this.makingOffer = false;
        }
    }

    private async reconnect(): Promise<void> {
        this.logWithTimestamp("尝试重连... ");
        this.onConnected && this.onConnected("Reconnecting...");
        // 清理旧连接
        if (this.pc) {
            this.pc.close();
        }
        // 重置变量
        this.makingOffer = false;
        this.waitingAnswer = false;
        this.ignoreOffer = false;
        this.pendingCandidates = [];
        this.reconnectAttempts = 0;
        this.isLoopRunning = false;
        this.polite = true;
        this.reconnectAttempts = 0;
        this.mutedTimes = 0;
        this.clearUnityLostCheck();
        this.isWsOpen = false;

        // 重新生成connectionId
        this.connectionId = this.generateConnectionId();
        console.log("-----------  重新生成 connectionId", this.connectionId);
        // 重新连接 websocket
        console.log("----------- 重新连接 websocket");
        this.reconnectWebSocket();
        // 重新初始化连接
        this.initWebsocket();
        await this.waitForWsOpen();
        this.initializePeerConnection();

        // 重新创建连接
        this.createConnection();

        // 开始重发 offer 的循环
        this.startResendOfferLoop();
    }

    // 在连接失败时调用
    private async handleConnectionFailure(): Promise<void> {
        console.log("----------- handleConnectionFailure");
        this.connectionId = this.generateConnectionId();
        console.log("----------- handleConnectionFailure 生成新的 connectionId", this.connectionId);
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++;
            this.logWithTimestamp(
                `Reconnection attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts}`,
            );
            await this.reconnect();
        } else {
            this.logWithTimestamp("Max reconnection attempts reached");
            this.onLoss && this.onLoss(1);
        }
    }

    // private startUnityLostMessageInterval(): void {
    //     this.logWithTimestamp(" 开始unity lost 测试...");
    //     this.unityLostMessageTestTimer = setInterval(() => {
    //         this.onSend && this.onSend({ command: "UnityLost", streamerId: this.unityId });
    //         this.logWithTimestamp("测试消息 UnityLost 发送");
    //     }, 60000); // 1 minute interval
    // }
}
